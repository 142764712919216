import "./styles.scss"

import React from "react"
import { motion } from "framer-motion"

import WallpaperTile from "components/WallpaperTile"

import { useProducts } from "context/products"

import fadeAnimation from "utils/fadeAnimation"

const AnimatedWallpaperTile = motion(WallpaperTile)

const Content = ({ pageData }) => {
  const { products } = useProducts()

  return (
    <section className="bestseller-content">
      <div className="container-fluid">
        <div className="row">
          <div className="col-xl-8 offset-xl-1">
            <motion.div
              className="bestseller-content__description"
              dangerouslySetInnerHTML={{ __html: pageData?.content }}
              {...fadeAnimation}
            />
          </div>
        </div>

        <div className="row">
          {products?.length > 0 ? (
            products
              ?.filter(item =>
                item.productCategories.nodes.some(
                  node => node.slug === "bestseller"
                )
              )
              ?.map((item, index) => (
                <div className="col-md-4" key={index}>
                  <AnimatedWallpaperTile item={item} {...fadeAnimation} />
                </div>
              ))
          ) : (
            <motion.p className="favorites-content__info" {...fadeAnimation}>
              Brak <strong>produktów</strong> w bestseller.
            </motion.p>
          )}
        </div>
      </div>
    </section>
  )
}

export default Content
